export const HND = 1
export const BACHELOR = 2
export const PGCE = 3
export const PHD = 4

export const EducationDegrees = {
  [HND]: { id: HND, name: 'HND' },
  [BACHELOR]: { id: BACHELOR, name: 'Bachelor' },
  [PGCE]: { id: PGCE, name: 'PGCE' },
  [PHD]: { id: PHD, name: 'PhD' },
}

export type EducationDegree = typeof HND | typeof BACHELOR | typeof PGCE | typeof PHD
