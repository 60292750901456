import { PropType } from 'vue'
import File from '@/models-ts/File'

export default {
  props: {
    cv: {
      type: Array as PropType<Array<File>>
    },
  },
  methods: {
  },
}
