import Vue, { PropType } from 'vue'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import Education from '@/models-ts/user/Education'
import { EducationDegrees } from '@/constants/user/educationDegrees'

export default Vue.extend<any, any, any, any>({
  components: {
    PrefferedCurrency,
  },
  props: {
    educations: Array as PropType<Array<Education>>,
  },
  computed: {
  },
  methods: {
    getDegree (ed: Education) {
      return EducationDegrees[ed.degree!].name
    },
  },
})
