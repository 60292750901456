import Vue, { PropType } from 'vue'
import FreelancerCard from '@/partials/FreelancerCards/FreelancerCard/FreelancerCard.vue'
import FreelancerListItem from '@/models-ts/user/FreelancerListItem'

export default Vue.extend<any, any, any, any>({
  components: { FreelancerCard },
  props: {
    freelancers: Array as PropType<Array<FreelancerListItem>>,
    skill: String,
    skillDetails: Object,
  },
})
