import { PropType } from 'vue'
import Review from '@/models-ts/user/Review'
import ReviewCard from '@/partials/ReviewCardNew/ReviewCard.vue'

export default {
  components: {
    ReviewCard,
  },
  props: {
    reviews: Array as PropType<Array<Review>>,
  },
}
