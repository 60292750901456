import BigNumber from 'bignumber.js'
import Vue from 'vue'
import Skill from '@/models-ts/Skill'
import FreelancerDetails from '@/models-ts/user/details/FreelancerDetails'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import { FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import Channel from '@/models-ts/user/Channel'
import { Channel as Channels } from '@/constants/user/channels'
import { getSocialLink } from '@/utils-ts/strings'
import { mapActions, mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  components: {
    PrefferedCurrency,
  },
  props: {
    profile: FreelancerDetails,
    isNotBanned: Boolean,
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    country () {
      const country = (this.countries || []).find((c: any) => c.id === this.profile.internalCountryId)
      return country?.name
    },
    skillsTags () {
      return this.profile.skills.map((skill: Skill) => ({
        text: skill.name,
        ...(!skill.is_custom && skill.url && { link: { name: FREELANCERS_LIST_BY_SKILL, params: { skill: skill.url } } })
      }))
    },
    rate () {
      if (new BigNumber(this.profile.rate).gt(0)) {
        return formatUsd(this.profile.rate)
      }
    },
    website () {
      if (this.profile.website) {
        try {
          const url = new URL(this.profile.website)
          return { link: this.profile.website, domain: url.hostname }
        } catch (e) {
          if (!this.profile.website.startsWith('http')) {
            return { link: `http://${this.profile.website}`, domain: this.profile.website }
          }
        }
      }
    },
    contacts () {
      const facebook = this.getChannel(Channels.Facebook)
      const linkedIn = this.getChannel(Channels.LinkedIn)
      const twitter = this.getChannel(Channels.Twitter)
      const github = this.getChannel(Channels.Github)
      return [
        { title: 'Facebook', icon: 'facebook-circle', value: facebook, link: getSocialLink(Channels.Facebook, facebook) },
        { title: 'LinkedIn', icon: 'linkedin-circle', value: linkedIn, link: getSocialLink(Channels.LinkedIn, linkedIn) },
        { title: 'Twitter', icon: 'twitter-circle', value: twitter, link: getSocialLink(Channels.Twitter, twitter) },
        { title: 'Github', icon: 'github-circle', value: github, link: getSocialLink(Channels.Github, github) },
      ]
    },
  },
  created () {
    try {
      this.getCountries()
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapActions({
      getCountries: 'countries/getCountries',
    }),
    getChannel (type: Channels) {
      const channels = this.profile.channels as Array<Channel>
      return channels.find(ch => ch.type === type)?.value || ''
    },
  }
})
