import { PropType } from 'vue'
import PortfolioProjectCard from './PortfolioProjectCard/PortfolioProjectCard.vue'
import PortfolioProject from '@/models-ts/user/PortfolioProject'

export default {
  props: {
    portfolio: {
      type: Array as PropType<Array<PortfolioProject>>
    },
  },
  components: {
    PortfolioProjectCard,
  },
}
