import Vue from 'vue'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import FreelancerDetails from '@/models-ts/user/details/FreelancerDetails'
import animateScrollTo from '@/utils/animateScrollTo'
import { FREELANCERS_LIST } from '@/constants/routes'
import { isBefore } from '@/utils/date'
import responseMixin from '@/mixins/responseMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import Education from './Education/Education.vue'
import GeneralInfo from './GeneralInfo/GeneralInfo.vue'
import CvTab from './CvTab/CvTab.vue'
import GigsTab from './GigsTab/GigsTab.vue'
import PortfolioTab from './PortfolioTab/PortfolioTab.vue'
import ReviewsTab from './ReviewsTab/ReviewsTab.vue'
import WorkExperience from './WorkExperience/WorkExperience.vue'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, canonicalMixin],
  components: {
    GeneralInfo,
    WorkExperience,
    Education,
    ReviewsTab,
    PortfolioTab,
    GigsTab,
    CvTab,
  },
  data () {
    return {
      acitveTab: 'gigs',
      FREELANCERS_LIST,
    }
  },
  computed: {
    ...mapState<RootState>({
      prefetched: (state: any) => state.freelancerDetails.prefetched,
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    ...mapGetters({
      freelacnerId: 'freelancerDetails/id',
      freelancer: 'freelancerDetails/freelancer',
      isLoading: 'freelancerDetails/isLoading',
    }),
    isNotBanned () {
      return !this.freelancer.bannedAt || isBefore(this.freelancer.bannedAt, Date.now())
    },
    // chatPartnerId () {
    //   return this.freelacnerId
    // },
    // chatPartnerRole () {
    //   return Roles.FREELANCER
    // },
    baseRoute () {
      return { name: this.$route.name, params: this.$route.params }
    },
    hasCvOnly () {
      return this.freelancer.cv.length &&
        !this.freelancer.gigs.length &&
        this.freelancer.portfolio.length &&
        !this.freelancer.reviews.length
    },
    metaTitle () {
      if (!this.freelancer) {
        return 'Loading'
      }
      const freelancer = this.freelancer as FreelancerDetails
      let title = `Freelancer ${freelancer.fullName}`
      if (freelancer.workExperience.length) {
        const organizations = [...freelancer.workExperience].reverse().slice(0, 3).map(exp => exp.organization)
        title += ` - Work experience: ${organizations.join(', ')}`
      } else if (this.freelancer.skills.length) {
        title += ` - Skills: ${freelancer.skills.slice(0, 3).map(skill => skill.name).join(', ')}`
      }
      return title
    }
  },
  watch: {
    '$route.params': {
      handler () {
        if (this.$route.params.id) {
          this.loadFreelancer()
        }
      }
    }
  },
  async prefetch () {
    try {
      if (process.server) {
        await this.$store.dispatch('freelancerDetails/load', this.$route.params.id)
        this.$store.commit('freelancerDetails/setPrefetched', true)
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        console.error('Fetch freelancer details error:', e)
      }
    }
  },
  async created () {
    try {
      if (process.client) {
        if (!this.prefetched) {
          await this.loadFreelancer()
        } else {
          this.setPrefetched(false)
        }
        await this.loadFreelancer()
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  beforeDestroy () {
    this.clearFreelancer()
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'freelancerDetails/setPrefetched',
      clearFreelancer: 'freelancerDetails/clearFreelancer',
    }),
    ...mapActions({
      load: 'freelancerDetails/load',
      openModal: 'ui/openModal',
    }),
    async loadFreelancer () {
      try {
        await this.load(this.$route.params.id)
        if (this.$route.hash) {
          const [, id] = this.$route.hash.split('#')
          if (id) {
            const el = document.getElementById(id)
            if (el) {
              animateScrollTo(document.getElementById(id))
            }
          }
        }
      } catch (e) {
        if (ErrorMatcher.isNotFound(e)) {
          this.setNotFound(true)
        } else {
          throw e
        }
      }
    },
    // onSendMessageClick () {
    //   this.openModal({
    //     component: 'lx-lazy-modal',
    //     props: {
    //       factory: import(/* webpackChunkName: "chat-modals" */ '@/modals/ChooseChatTopic/ChooseChatTopic.vue'),
    //       title: 'Choose a topic',
    //       props: {
    //         freelancer: this.freelancer,
    //       }
    //     }
    //   })
    // },
  },
  metaInfo () {
    const meta = []
    if (this.freelancer) {
      if (!this.isNotBanned) {
        meta.push({
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        })
      }
      meta.push({
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: `${this.freelancer.fullName} Freelancer's public profile - gigs, work experience, portfolio, reviews, skills and reputation`
      })
      meta.push({
        vmid: 'og:description',
        property: 'og:description',
        // eslint-disable-next-line max-len
        content: `${this.freelancer.fullName} Freelancer's public profile - gigs, work experience, portfolio, reviews, skills and reputation`
      })
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: `Freelancer ${this.freelancer.fullName}`
      })
      meta.push({
        vmid: 'og:image',
        property: 'og:image',
        content: this.freelancer.avatar.getMaxAvatar(this.freelancer.id)
      })
      meta.push({
        vmid: 'og:type',
        property: 'og:type',
        content: 'profile'
      })
      meta.push({
        vmid: 'profile:first_name',
        property: 'profile:first_name',
        content: this.freelancer.firstName
      })
      meta.push({
        vmid: 'profile:last_name',
        property: 'profile:last_name',
        content: this.freelancer.lastName
      })
      if (!this.freelancer.isPretty) {
        meta.push({
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        })
      }
    }

    return {
      title: this.metaTitle,
      meta,
      link: [this.canonicalLink],
    }
  },
})
