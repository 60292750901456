import Vue from 'vue'
import { mapActions } from 'vuex'
import File from '@/models-ts/File'
import responseMixin from '@/mixins/responseMixin'
import PortfolioProject from '@/models-ts/user/PortfolioProject'
import Image from '@/models/Image'
import { isImage } from '@/utils/file'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  props: {
    project: PortfolioProject,
  },
  computed: {
    files () {
      return this.project.getFiles()
    },
    images () {
      return this.files.filter(isImage)
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    isImage,
    onClickToImage (image: Image) {
      // @ts-ignore
      const index = this.images.findIndex((im: Image) => im.id === image.id)
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
          props: {
            title: this.project.projectName,
            images: this.images,
            initialImage: index,
          },
        }
      })
    },
  },
})
