import { PropType } from 'vue'
import GigCard from '@/partials/GigCard/GigCard.vue'
import GigShort from '@/models-ts/gigs/GigShort'

export default {
  props: {
    gigs: {
      type: Array as PropType<Array<GigShort>>
    },
  },
  components: {
    GigCard,
  },
  methods: {
  },
}
