import { render, staticRenderFns } from "./GigsTab.htm?vue&type=template&id=39e69c52&scoped=true&"
import script from "./GigsTab.ts?vue&type=script&lang=js&"
export * from "./GigsTab.ts?vue&type=script&lang=js&"
import style0 from "./GigsTab.scss?vue&type=style&index=0&id=39e69c52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e69c52",
  null
  
)

export default component.exports