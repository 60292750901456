import Vue from 'vue'
import unescape from 'lodash/unescape'
import { FREELANCER_PROFILE, FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import FreelancerListItem from '@/models-ts/user/FreelancerListItem'
import Skill from '@/models-ts/Skill'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'

export default Vue.extend<any, any, any, any>({
  props: {
    user: FreelancerListItem,
    resizable: Boolean,
  },
  components: {
    UserInfo
  },
  data () {
    return {
      FREELANCER_PROFILE,
    }
  },
  computed: {
    aboutMe () {
      return unescape(this.user?.bio || '')
    },
    skills () {
      return this.user.skills.map((skill: Skill) => ({
        text: skill.name,
        ...(!skill.is_custom && skill.url && { link: { name: FREELANCERS_LIST_BY_SKILL, params: { skill: skill.url } }
        })
      }))
    },
    skillsSlice () {
      return this.isTabletMax ? 1 : 2
    },
    rate () {
      let rate = this.user.rate
      if (rate && Number(rate) !== 0) {
        return formatUsd(rate, 2, true)
      }
    },
  },
})
