import Vue, { PropType } from 'vue'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import WorkExperience from '@/models-ts/user/WorkExperience'
import { formatDate } from '@/utils/date'

export default Vue.extend<any, any, any, any>({
  components: {
    PrefferedCurrency,
  },
  props: {
    experience: Array as PropType<Array<WorkExperience>>,
  },
  computed: {
  },
  methods: {
    getRange (exp: WorkExperience) {
      return `${formatDate(exp.from, 'MMMM YYYY')} – ${exp.isNow() ? 'Current time' : formatDate(exp.to, 'MMMM YYYY')}`
    },
  },
})
